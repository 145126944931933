.Menu {
    background-color: #222;
    text-align: left;
    padding-left: 10px;
    user-select: none;
}
.menu-title {
    display: inline-block;
    margin: 10px 10px;
}
.menu-item {
    display: inline-block;
    margin: 10px 10px;
    color: white;
    cursor: pointer;
}
.menu-item-active {
    color: gold;
}