.Home {
  margin-left: 0px;
  text-align: left;
}

.Home .introduction {
  margin-left: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.Home .matrix {
  color: #56ff56;
}



.section-container {

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 5px;

}

@media only screen and (max-width: 480px) {
  /* your CSS here */
  .section {
    width: 95%;
  }
}

.section {
  border: 0px solid #555;
  display: inline-block;
  flex: 1 0 auto;
  border-radius: 10px;
  margin: 10px;
  background-color: #222;
  text-align: center;
  position: relative;
  padding-bottom: 35px;
  min-width: 30%;
  max-width: 96%;
}

.section-header {
  background-color: #121212;
  margin-bottom: 10px;
  padding: 6px;
  color: #ffffe0;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.section-header h2 {
  font-size: 1.0em;
  margin: 0;
}

.section-footer {
  background-color: #121212;
  margin-top: 10px;
  padding: 6px;
  color: #ffffe0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  font-size: 0.8em;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
}



.section-margin {
  margin-left: 10px;
  margin-right: 10px;
  /* word-wrap: break-word; */
  /* word-break: break-all; */
  
}