.Projects {
  margin: 20px 20px 0 20px;
  text-align: left;
}

.Projects .project-item {
  margin-bottom: 25px;
  overflow: hidden;
}

.Header {
  margin: 5px 0px 10px 0px;
  font-weight: bold;
}
.Segment {
  position: relative;
  border: 0px solid #666;
  border-radius: 5px;
  background-color: #222;
  margin: 20px 0px;
  padding: 10px;
}