
.outer {
    display: table;
    /*position: absolute;*/
    /*height: 100%;*/
    width: 100%;
    min-height: 140px;
}

.middle {
    display: table-cell;
    vertical-align: middle;
    padding-top: 5px;
}

.inner {
    margin-left: auto;
    margin-right: auto; 
    width: auto;
}

.doomguy {
	background-image: url('doomguy.png');
	height: 64px;
	width: 48px;
	display: inline-block;
	/*transform: scale(3);*/
}

.doomguy1 {background-position:0px 0px}
.doomguy2 {background-position:0px -64px}
.doomguy3 {background-position:0px -128px;}
.doomguy4 {background-position:0px -192px;}
.doomguy5 {background-position:0px -256px;}
.doomguy6 {background-position:0px -320px;}
.doomguy7 {background-position:0px -384px;}
.doomguy8 {background-position:0px -448px;}
.doomguy9 {background-position:0px -512px;}
.doomguy10 {background-position:0px -576px;} /* this one is an exception to the 64 rule */
.doomguy11 {background-position:0px -640px}
.doomguy12 {background-position:0px -704px}
.doomguy13 {background-position:0px -768px;}
.doomguy14 {background-position:0px -832px;}
.doomguy15 {background-position:0px -896px;}
.doomguy16 {background-position:0px -960px;}
.doomguy17 {background-position:0px -1024px;}
.doomguy18 {background-position:0px -1088px;}
.doomguy19 {background-position:0px -1152px;}
.doomguy20 {background-position:0px -1216px;}
.doomguy20 {background-position:0px -1280px;}
.doomguy21 {background-position:0px -1344px;}
.doomguy22 {background-position:0px -1408px;}
.doomguy23 {background-position:0px -1472px;}
.doomguy24 {background-position:0px -1536px;}
.doomguy25 {background-position:0px -1600px;}
.doomguy26 {background-position:0px -1664px;}

.doomguy-spin {
  animation: key-doomguy-spin infinite 20s linear;
}

@keyframes key-doomguy-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}


.doomguy-wrap {
  /* background-color: #111; */
  padding: 10px 0;
  /* margin: 0 10px 10px 10px; */
}
.doomguy-html {
  font-size: 1.1em;
}