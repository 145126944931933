a {
	color: gold;
}
a:hover {
	color: yellow;
	text-decoration: underline;
}

.main {
	padding: 0px;
	/*margin: 15px 15px;*/
}

.text-align-left {
	text-align: left;
}



.header-after-area {
	background-color: rgba(5,5,5,0.5);
	height: 100px;
	color: white;
	display: block;
}

.header-after-area select {
	color: black;
}

.footer-area {
	min-height: 50vw;
	background-color: #394f6b;
	
	background-size: cover;
	position: relative;
}

.margin-left-10 {
	margin-left: 10px !important;
}

